import { DialogHandler } from "../../components/dialog/useDialogHandler";
import { FbcPosUserCreateRequest } from "../../model/fbc/fbc.pos.user";
import HandlerDialog from "../../components/dialog/handlerDialog";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import { useState } from "react";
import { asNonEmptyString } from "../../util/util_extensions";

export default function DialogCreateFamilyBonusClubPosUser({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<void>;
  onConfirm: (user: FbcPosUserCreateRequest) => void;
}) {
  const [username, setUsername] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");

  const savePossible = () => {
    const regex = /^[A-Za-z\-_0-9]+$/;
    const validLengths = asNonEmptyString(username) && asNonEmptyString(password) && password === passwordRepeat;
    if (!validLengths) {
      return false;
    }
    if (username.length < 4) {
      return false;
    }
    const usernameMatchesFormat = Array.from(username).every((char) => regex.test(char));
    if (!usernameMatchesFormat) {
      return false;
    }
    const regexForLettersOnly = /^[A-Za-z]+$/;
    return regexForLettersOnly.test(username.charAt(0));
  };

  return (
    <HandlerDialog handler={handler}>
      <DialogTitle id="alert-dialog-title">
        <Typography sx={{ font: "normal normal bold 18px/21px Manrope" }}>
          {intl.get("family_bonus_club.pos_users.new_user.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{
            __html: intl.get("family_bonus_club.pos_users.new_user.message"),
          }}
        />
        <Grid item container direction="column">
          <TextFieldWithLabel
            required={true}
            label={intl.get("family_bonus_club.pos_users.new_user.label_username")}
            id={"username"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            value={username}
          />
          <TextFieldWithLabel
            required={false}
            label={intl.get("family_bonus_club.pos_users.new_user.label_display_name")}
            id={"display_name"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setDisplayName(event.target.value);
            }}
            value={displayName}
          />
          <TextFieldWithLabel
            type="password"
            required={true}
            label={intl.get("family_bonus_club.pos_users.new_user.label_password")}
            id={"password"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            value={password}
          />
          <TextFieldWithLabel
            type="password"
            required={true}
            label={intl.get("family_bonus_club.pos_users.new_user.label_password_repeat")}
            id={"password_repeat"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setPasswordRepeat(event.target.value);
            }}
            value={passwordRepeat}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handler.close} variant={"outlined"} color={"defaultTextColor"}>
          {intl.get("family_bonus_club.pos_users.new_user.action_cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm({ username: username, display_name: displayName, password: password });
          }}
          color="secondary"
          autoFocus
          disabled={!savePossible()}
        >
          {intl.get("family_bonus_club.pos_users.new_user.action_create")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
